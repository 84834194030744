<template lang="pug">
  b-card.manager-organizations-machines-list.card-white.card-no-gutters.shadow(no-body)
    wc-card-tabs(:tabs='tabs')
    b-card-header.bg-transparent.border-0
      wc-tables-header(
        :title="$t('activerecord.models.machine.other')"
        :action-new='policies.new'
        route-new=''
        @click-data-clean-fetch='dataCleanFetch'
        @click-data-fetch='dataFetch')

    b-card-body
      b-row
        b-col
          wc-tables-filter(
            :filter.sync='filter'
            :p-page.sync='pagination.pPage'
            :p-page-options='pagination.pPageOptions'
            @submit='dataFetch'
            @submit-clear='filterClear')

      b-row
        b-col
          transition(name='fade')
            wc-tables-wrapper-default(
              :ref='wns'
              :items='items'
              :fields='fields'
              :sort-by.sync='sorting.sortBy'
              :sort-desc.sync='sorting.sortDesc'
              :is-loading='isLoading'
              :action-delete='actionDestroy'
              @row-clicked='rowClicked'
              @row-delete='rowDelete'
              @sort-changed='sortingChanged'
              attr-active)
              template(v-slot:cell(attributes.model_name)='data')
                | {{ $getDeep(included.find(x => x.type === 'model' && x.id === $getDeep(data.item, 'relationships.model.data.id')), 'attributes.name') }}

              template(v-slot:cell(attributes.firmware_version)='data')
                | {{ $getDeep(included.find(x => x.type === 'firmware' && x.id === $getDeep(data.item, 'relationships.firmware.data.id')), 'attributes.version') }}

              template(v-slot:cell(attributes.state)='data')
                b-badge.state.p-2(
                  :variant="$wc.conf.states.machine.variants[data.item.attributes.state]"
                  pill)
                  i.fa-fw(:class="$wc.conf.states.machine.icons[data.item.attributes.state]")
                  |  {{ $t('machine.states.' + data.item.attributes.state) }}

    b-card-footer.border-0.bg-white
      wc-tables-footer(
        :total-items='pagination.totalItems'
        :p-page='pagination.pPage'
        :c-page.sync='pagination.cPage')

    loading(
      :active.sync='isLoading'
      :can-cancel-esc='true'
      :on-cancel='onCancel')
</template>

<script>
import Tabs from '@views/container/manager/organizations/shared/tabs'
import Table from '@common/table'
import apiMachines from '@services/api/manager/organizations/machines'

export default {
  name: 'manager-organizations-machines-list',
  mixins: [Tabs, Table],
  props: {
    wns: {
      type: String,
      default: 'mac',
    },
  },
  methods: {
    routeParams(item) {
      return {
        edit: [{ name: 'manager-machines-edit', params: { machine_id: item.attributes.sid } }],
      }
    },
    apiParams() {
      return {
        list: [{ organization_id: this.organization_id }, this.sortingParams],
      }
    },
  },
  data() {
    return {
      apiBase: apiMachines,
      actionRowClicked: 'edit',
      organization_id: this.$route.params.organization_id,
      fields: [
        {
          key: 'id',
          label: this.$t('activerecord.attributes.shared.id'),
          sortable: true,
          attr: 'machines.id',
          class: 'dots-100 h-100',
          noClick: true,
        },
        {
          key: 'attributes.serial',
          label: this.$t('activerecord.attributes.machine.serial'),
          sortable: true,
          attr: 'machines.serial',
        },
        {
          key: 'attributes.model_name',
          label: this.$t('activerecord.attributes.machine.model'),
          sortable: true,
          attr: 'models.name',
        },
        {
          key: 'attributes.firmware_version',
          label: this.$t('activerecord.models.firmware.one'),
          sortable: true,
          attr: 'firmwares.version',
        },
        {
          key: 'attributes.state',
          label: this.$t('activerecord.attributes.shared.state'),
          sortable: true,
          attr: 'machines.state',
        },
        {
          key: 'attributes.discarded_at',
          label: this.$t('activerecord.attributes.shared.discarded_at'),
          sortable: true,
          attr: 'machines.discarded_at',
        },
        {
          key: 'attributes.updated_at',
          label: this.$t('activerecord.attributes.shared.updated_at'),
          sortable: true,
          attr: 'machines.updated_at',
        },
        {
          key: 'attributes.created_at',
          label: this.$t('activerecord.attributes.shared.created_at'),
          sortable: true,
          attr: 'machines.created_at',
        },
        {
          key: 'delete',
          label: this.$t('shared.actions.delete'),
          class: 'text-right',
        },
      ],
    }
  },
}
</script>
